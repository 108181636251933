import { Box, Container, useMediaQuery } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import MobileDrawer from 'components/MobileDrawer';
import { useAuth } from 'contexts/auth';
import React, { useEffect } from 'react';
import {
  matchPath,
  Redirect,
  useHistory,
  useLocation,
  Link as RouterLink,
} from 'react-router-dom';
import config from 'routes/config';
import { Main } from './styles';
import Quentao from 'images/logo.webp';

function LoggedLayout(props) {
  const { signOut } = useAuth();
  const { children, routes } = props;
  const history = useHistory();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  let location = useLocation();
  const { user } = useAuth();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const activeRoute = Object.keys(config)
    .map(key => config[key])
    .flat()
    .find(route => matchPath(location.pathname, route));

  useEffect(() => {
    history.listen(() => {
      setMobileOpen(false);
    });
  }, [history]);

  if (!activeRoute) {
    return <Redirect to="/" />;
  }

  const rolesToCheck = ['admin', 'contador'];
  const menuItems = routes.protected
    .filter(route => !route.hideFromMenu)
    .filter(route => {
      // Filtra os atributos da rota para verificar apenas os que estão em rolesToCheck
      const routeRoles = Object.keys(route).filter(key =>
        rolesToCheck.includes(key),
      );

      if (routeRoles.length === 0) {
        // Se a rota não possui os atributos de permissão, permite acesso
        return true;
      }

      // Verifica se pelo menos um dos atributos relevantes é true tanto no user quanto na rota
      return routeRoles.some(role => user[role] && route[role]);
    });

  return (
    <>
      <CssBaseline />
      <Container
        id="menu"
        style={{
          maxWidth: 'none',
        }}
      >
        <Box display="flex" alignItems="center">
          <Box display="flex" alignItems="center" justifyContent="center">
            <RouterLink to="/">
              <img
                draggable={false}
                style={{ height: isMobile ? 50 : 50 }}
                src={Quentao}
                alt="Quentao's Logo"
              />
            </RouterLink>
          </Box>
          <Hidden>
            <Box my={1} ml="auto" id="mobile-menu">
              <MobileDrawer
                onClose={() => setMobileOpen(false)}
                onOpen={() => setMobileOpen(true)}
                open={mobileOpen}
                menuItems={menuItems}
                onSignout={signOut}
              />
            </Box>
          </Hidden>
        </Box>
      </Container>
      <Main>{children}</Main>
    </>
  );
}

export default LoggedLayout;
