import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from 'contexts/auth';

function PrivateRoute({ children, admin, contador, ...rest }) {
  const { user, userLoading, signed } = useAuth();

  // Lógica de permissão
  const hasPermission =
    !userLoading &&
    (() => {
      // Se não houver atributos de permissão, a rota é acessível
      if (admin === undefined && contador === undefined) {
        return true;
      }
      // Se houver somente admin, verifica user.admin
      if (admin !== undefined && contador === undefined) {
        return user.admin;
      }
      // Se houver somente contador, verifica user.contador
      if (contador !== undefined && admin === undefined) {
        return user.contador;
      }
      // Se ambos os atributos existirem, verifica se pelo menos um deles é permitido
      return (admin && user.admin) || (contador && user.contador);
    })();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        signed && hasPermission ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
